<template>
  <div>
    <detail-header></detail-header>
    <div class="section">
      <h2 class="title">用户信息</h2>
      <el-form>
        <el-row>
          <el-col :span="8">
            <el-form-item label="姓名："> {{ userInfo.realName }}</el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="联系电话：">
              {{ userInfo.phone }}
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="openId：">
              {{ userInfo.openid }}
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="实名认证：">
              <span
                v-if="
                  userInfo.hasVerify === this.const.HAS_VERIFY_CODE.UNVERIFIED
                "
                >未实名认证</span
              >
              <span v-else class="option option-primary" @click="showVerifyInfo"
                >已实名认证</span
              >
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="邀请人：">
              {{ userInfo.inviteUid }}--
              {{ userInfo.inviteRealName }}</el-form-item
            >
          </el-col>

          <el-col :span="8">
            <el-form-item label="注册时间：">
              {{ userInfo.createAt }}</el-form-item
            >
          </el-col>

          
          <el-col :span="8">
            <el-form-item label="代理：">
              {{ userInfo.operationName }}</el-form-item
            >
          </el-col>


        </el-row>
      </el-form>
    </div>

    <!-- 钱包状态 -->
    <div class="section">
      <h2 class="title">套餐信息</h2>
      <el-form>
        <el-row>
          <el-col :span="8">
            <el-form-item label="押金：">
              {{ util.formatNum(userInfo.money / 100) }}
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="套餐到期时间：">
              <span v-if="!validatenull(userInfo.endTime)">{{
                $filter.dateFormat(new Date(userInfo.endTime))
              }}</span>
              <span v-else>无</span>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="套餐购买次数：">
              {{ userInfo.bugNums }}
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="消费总金额：">
              {{ userInfo.allMoney / 100 }}
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="在网时长：">
              {{ userInfo.bugTime }} (天)</el-form-item
            >
          </el-col>
        </el-row>
      </el-form>
    </div>

    <!-- 换电记录 -->
    <div class="section">
      <h2 class="title">换电记录</h2>
      <div>
        <ExchangeRecord
          :listQuery="exchangeRecordQuery"
          ref="ExchangeRecord"
        ></ExchangeRecord>
      </div>
    </div>

    <!-- 违约记录 -->
    <!-- <div class="section">
      <h2 class="title">违约记录</h2>
      <div>
        <PenaltyRecord
          :listQuery="penaltyRecordQuery"
          ref="penaltyRecord"
        ></PenaltyRecord>
      </div>
    </div> -->

    <!-- 开门记录 -->
    <div class="section">
      <h2 class="title">开门记录</h2>
      <div>
        <UserEventRecord
          ref="UserEventRecord"
          :listQuery="UserEventQuery"
          :pageable="false"
        ></UserEventRecord>
      </div>
    </div>

    <div class="section">
      <h2 class="title">套餐购买记录</h2>
      <div>
        <OrderProductRecord
          ref="OrderProductRecord"
          :listQuery="productOrderQuery"
          :pageable="false"
        ></OrderProductRecord>
      </div>
    </div>

    <VerifyInfoModal
      :modalData="modalData"
      :ref="MODAL_KEY.VERIFY_INFO"
    ></VerifyInfoModal>
  </div>
</template>

<script>
import ExchangeRecord from "@/components/view-components/exchange-record";
import PenaltyRecord from "@/components/view-components/penalty-record";
import { fetchBatteryManDetail, fetchBatteryManPage } from "@/api/batteryMan";
import VerifyInfoModal from "./components/VerifyInfoModal.vue";
import UserEventRecord from "@/components/view-components/user-event-record";
import OrderProductRecord from "@/components/view-components/order-product-record";
import moment from "moment";

export default {
  components: {
    OrderProductRecord,
    ExchangeRecord,
    PenaltyRecord,
    VerifyInfoModal,
    UserEventRecord,
  },
  data() {
    let userId = this.$router.currentRoute.value.params.id;

    return {
      MODAL_KEY: {
        VERIFY_INFO: "VERIFY_INFO",
      },
      userId: userId,
      productOrderQuery: {
        userId: userId,
      },
      penaltyRecordQuery: {
        balanceStatus: -1,
        passTime: -1,
        type: -1,
        userId: userId,
        createAt: "",
        isCheck: -1,
        money: -1,
        vinfo: "",
        deviceUserId: -1,
        ruleId: -1,
        payStatus: -1,
      },
      UserEventQuery: {
        phone: "",
        userId: userId,
      },
      exchangeRecordQuery: {
        userId: userId,
      },
      userInfo: {},
      modalData: {},
    };
  },
  methods: {
    initData() {
      this.$refs["ExchangeRecord"].getList();

      this.$refs["OrderProductRecord"].getList();
    },

    // 展示实名认证信息
    showVerifyInfo() {
      this.modalData = {
        front: this.userInfo.certificateImg1,
        back: this.userInfo.certificateImg2,
      };
      this.$refs[this.MODAL_KEY.VERIFY_INFO].showModal();
    },

    getUserDetail() {
      fetchBatteryManDetail({ id: this.userId }).then((res) => {
        this.userInfo = Object.assign(this.userInfo, res.data.data);
        this.UserEventQuery.phone = this.userInfo.phone;
        this.$refs["UserEventRecord"].getList(1);
      });

      fetchBatteryManPage({ id: this.userId }).then(res=>{
        let data = res.data.data.records[0];
        this.userInfo = Object.assign(this.userInfo, data);
      })
    },
  },

  mounted() {
    this.getUserDetail();
    this.initData();
  },
};
</script>

<style scoped>
.section {
  border-bottom: 1px solid #e1e1e1;
  padding: 30px 10px;
}
.section .title {
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 20px;
}
</style>