<template>
  <el-table
    border
    :data="dataSource"
    class="page-top-space"
    row-class-name="table-row"
  >
    <el-table-column type="selection"></el-table-column>
    <el-table-column label="姓名" prop="realName"></el-table-column>
    <el-table-column label="手机号" prop="phone"></el-table-column>
    <el-table-column label="订单编号" prop="orderNo"></el-table-column>
    <el-table-column label="套餐名称" prop="goodsName"></el-table-column>

    <el-table-column label="套餐类型" prop="orderType">
      <template #default="scope">
        <eb-badge :list="this.const.ORDER_TYPE" :target="scope.row.orderType"></eb-badge>
      </template>
    </el-table-column>

    <el-table-column label="订单状态" prop="orderStatus">
      <template #default="scope">
        <eb-badge
          :list="this.const.ORDER_STATUS"
          :target="scope.row.orderStatus"
        ></eb-badge>
      </template>
    </el-table-column>

    <el-table-column label="奖励金状态" prop="rewardStatus">
      <template #default="scope">
        <eb-badge
          :list="this.const.REWARD_STATUS"
          :target="scope.row.rewardStatus"
        ></eb-badge>
      </template>
    </el-table-column>

    <el-table-column label="商品金额" prop="totalAmount">
      <template #default="scope">
        <span>{{ this.util.formatNum(scope.row.totalAmount / 100) }}</span>
      </template>
    </el-table-column>

    <el-table-column label="订单金额" prop="payAmount">
      <template #default="scope">
        <span>{{ this.util.formatNum(scope.row.payAmount / 100) }}</span>
      </template>
    </el-table-column>

    <el-table-column label="下单时间" prop="createAt"></el-table-column>

    <el-table-column label="操作" prop="operation">
      <template #default="scope">
        <span
          class="option option-primary"
          v-if="scope.row.orderStatus == this.const.ORDER_STATUS_CODE.PAYMENT && scope.row.orderType == '1'"
          @click="showOrderRefundModal(scope.row)"
          >申请退款</span
        >

        <span
          v-if="
            scope.row.orderType == '2' &&
            this.const.ORDER_STATUS_CODE.PAYMENT == scope.row.orderStatus
          "
          class="option option-primary"
          @click="showDepositSubtractModal(scope.row)"
          >扣除押金</span
        >

        <span
          v-if="
            scope.row.orderType == '2' 
          "
          class="option option-primary"
          @click="closeDeposit(scope.row)"
          >关闭押金</span
        >
      </template>
    </el-table-column>
  </el-table>
  <el-pagination
    v-if="pageable"
    layout="total,prev, pager, next, jumper"
    background
    :total="total"
    :page-size="pages.pageSize"
    :current-page="pages.pageIndex"
    @current-change="getList"
  ></el-pagination>

  <ShowOrderRefundModal
    ref="orderRefundModal"
    :modalData="modalData"
    @submit="doOrderRefund"
  ></ShowOrderRefundModal>

  <OrderDepositSubtractModal
    ref="OrderDepositSubtractModal"
    :modalData="modalData"
    @submit="doSubtract"
  ></OrderDepositSubtractModal>
</template>

<script>
import { toRefs, toRef, reactive, ref } from "vue";
import { validatenull } from "@/common/validate";
import {
  fetchOrderList,
  orderRefund,
  depositSubtract,
  depositSubtractClose,
} from "@/api/order";
import ShowOrderRefundModal from "./components/orderRefundModal";
import OrderDepositSubtractModal from "./components/orderDepositSubtractModal";
import { ElMessage, ElLoading,ElMessageBox } from 'element-plus'

export default {
  components: { ShowOrderRefundModal, OrderDepositSubtractModal },
  props: ["listQuery", "pageable"],
  setup(props, contenxt) {
    let listQuery = toRef(props, "listQuery");

    const state = reactive({
      total: 0,
      dataSource: [],
      modalData: {},
      pages: {
        pageIndex: 1,
        pageSize: 10,
      },
      listQuery: listQuery,
    });
    let orderRefundModal = ref(null);

    let doOrderRefund = (data) => {
      orderRefund(data).then((res) => {
        orderRefundModal.value.closeModal();
        ElMessage({
                message: "退款申请成功，请勿重复申请！",
                type: 'success'
            });
      });
    };

    let showOrderRefundModal = (data) => {
      state.modalData = { ...data };
      orderRefundModal.value.showModal();
    };

    let getList = (current) => {
      if (!validatenull(current)) {
        state.pages.pageIndex = current;
      }

      fetchOrderList({ ...state.listQuery, ...state.pages }).then((res) => {
        state.dataSource = res.data.data.records;
        state.total = res.data.data.total;
      });
    };

    // 展示扣押金界面
    let OrderDepositSubtractModal = ref(null);
    let showDepositSubtractModal = (data) => {
      state.modalData = { ...data };
      OrderDepositSubtractModal.value.showModal();
    };

    // 扣除用户押金
    let doSubtract = (data) => {
      depositSubtract(data).then((res) => {
        OrderDepositSubtractModal.value.closeModal();
        ElMessage({
                message: "押金扣款成功！",
                type: 'success'
            });
        getList();
      });
    };

    // 关闭订单
    let closeDeposit = (data) => {
      
      ElMessageBox.confirm("确认要关闭订单吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then((res) => {
        depositSubtractClose({
          orderNo: data.orderNo,
        }).then((res) => {
          this.$message("");
          ElMessage({
                message: "关闭成功!",
                type: 'success'
            });
        });
      });
    };

    return {
      closeDeposit,
      showDepositSubtractModal,
      showOrderRefundModal,
      orderRefundModal,
      OrderDepositSubtractModal,
      doOrderRefund,
      doSubtract,
      getList,
      ...toRefs(state),
    };
  },
};
</script>