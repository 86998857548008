<template>
  <el-dialog
    title="订单退款"
    :close-on-click-modal="isClickModalClose"
    v-model="isShow"
    :width="defaultWidth"
    @close="closeModal"
  >
    <el-form
      ref="form"
      :model="modalData"
      :rules="ruleValidate"
      label-width="110px"
    >
      <el-form-item label="用户:" prop="passwordHash">
        <span>{{ modalData.realName }}</span>
      </el-form-item>

      <el-form-item label="订单金额：" prop="payAmount">
        <span>{{ modalData.payAmount / 100 }}</span>
      </el-form-item>

      <el-form-item label="退款金额：" prop="amount">
        <el-input
          v-model="modalData.amount"
          placeholder="请输入退款金额"
          :maxLength="10"
        ></el-input>
      </el-form-item>

      <el-form-item label="退款原因：" prop="reason">
        <el-input
          v-model="modalData.reason"
          placeholder="请输入退款原因"
          :maxLength="50"
        ></el-input>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button @click="closeModal"> 取消 </el-button>
      <el-button @click="submit" type="primary"> 确定 </el-button>
    </template>
  </el-dialog>
</template>

<script>
import modalMixin from "@/mixins/modalMixin";
import { toRefs, toRef, reactive, ref } from "vue";

export default {
  mixins: [modalMixin],
  emits: ["submit"],
  props: ["modalData"],
  setup(props, context) {
    let modalData = toRef(props, "modalData");
    let form = ref(null);
    let state = reactive({
      modalData: modalData,
      ruleValidate: {
        reason: [
          {
            required: true,
            message: "请输入退款原因",
            trigger: "change",
          },
        ],
        amount: [
          {
            required: true,
            message: "退款金额不能为空",
            trigger: "change",
          }, {
            validator: (rule, val, callback) => {
              if (val * 100 > state.modalData.payAmount) {
                callback("退款金额不能大于订单金额");
              } else {
                callback();
              }
            },
          },
        ],
      },
    });

    let submit = () => {
      let obj = {
        orderNo: state.modalData.orderNo,
        amount: state.modalData.amount * 100,
        reason: state.modalData.reason,
      };

      form.value.validate((res) => {
        if (res) {
          context.emit("submit", obj);
        }
      });
    };

    return {
      submit,
      form,
      ...state,
    };
  },
};
</script>
